<template>
  <div class="accountList">
    <div>
      <span v-if="myBrand" class="mr_20">{{ myBrand.name }}</span>
      <span v-else-if="myComp" class="mr_20">{{ myComp.name }}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{
        myShop.name
      }}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="名称：">
              <el-input
                v-model="searchForm.username"
                placeholder="请输入名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号：">
              <el-input
                v-model="searchForm.mobile"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="角色：">
              <el-select
                style="width: 100%"
                v-model="searchForm.role_id"
                filterable
                clearable
                placeholder="请选择角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.role_id"
                  :label="item.role_name"
                  :value="item.role_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账户状态">
              <el-select
                v-model="searchForm.status"
                placeholder="请选择"
                style="width: 100%"
                @change="serchData"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" v-if="myBrand || myComp">
            <el-form-item label="商家">
              <el-select
                style="width: 100%"
                v-model="searchForm.businessId"
                :remote-method="businessRemoteMethod"
                :loading="businessLoading"
                placeholder="选择商家"
                clearable
                filterable
                remote
                @focus="businessRemoteMethod()"
                @change="serchData"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div style="text-align: right; margin-right: 10px">
            <el-button type="primary" size="small" @click="serchData"
              >搜索</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="derive">
      <el-button
        size="small"
        style="float: right; margin-bottom: 20px"
        type="primary"
        v-if="managerType != 0 || getAuth('accountOperate')"
        @click="addUser()"
        >创建新账号</el-button
      >
    </div>
    <base-table
      :columns="businessUserlCol"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <el-table-column slot="status" label="状态" align="center" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">删除</span>
          <span v-if="scope.row.status == 1">正常</span>
          <span v-if="scope.row.status == 2">禁用</span>
        </template>
      </el-table-column>
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        width="120"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('accountOperate')) &&
              scope.row.status != 3
            "
            @click="amend(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('accountOperate')) &&
              scope.row.status != 0
            "
            @click="delAdmin(scope.row)"
            >删除</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="
              (managerType != 0 || getAuth('accountOperate')) &&
              scope.row.status == 0
            "
            @click="recoverAdmin(scope.row)"
            >恢复</el-button
          >
        </template>
      </el-table-column>
    </base-table>
    <account-modal ref="accountModal" />
  </div>
</template>

<script>
import columns from "./columns";
import { suggestBusinessList } from "@/api/business.js";
import accountModal from "./components/account-modal.vue";
import {
  listBusinessAdmin,
  businessStatusRoleList,
  statusBusinessAdmin,
} from "@/api/user.js";
import { getMyBrand, getMyComp, getMyShop, getAuth } from "@/utils/index.js";
export default {
  components: { accountModal },
  name: "accountList",

  data() {
    return {
      managerType: localStorage.getItem("__managerType__"),
      getAuth: getAuth,
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        status: 1,
      },
      roleList: [],
      statusList: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "禁用",
        },
        {
          value: 0,
          label: "删除",
        },
      ],
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],
    };
  },

  async mounted() {
    this.initData();
    this.getAllRoleList();
    this.baseBusinessList = await this.getSelectBusiness();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      this.getRepaymentList();
    },
    async getRepaymentList() {
      this.searchForm.seniorId = "";
      this.searchForm.entityId = "";
      if (!this.myBrand && !this.myComp && this.myShop) {
        this.searchForm.businessId = this.myShop.id;
      }
      if (!this.searchForm.businessId) {
        if (this.myBrand) {
          this.searchForm.seniorId = this.myBrand.id;
        } else if (this.myComp) {
          this.searchForm.entityId = this.myComp.id;
        }
      }
      this.getlistBusinessAdmin();
    },
    async getlistBusinessAdmin() {
      const { code, data } = await listBusinessAdmin(this.searchForm);
      if (code == 200) {
        console.log(data);
        this.tableData = data.data;
        this.total = data.total;
      }
    },
    addUser() {
      this.$refs.accountModal.isShow("add", "", this.roleList);
    },
    amend(row) {
      this.$refs.accountModal.isShow("edit", row, this.roleList);
    },
    delAdmin(row) {
      let that = this;
      this.$confirm("是否删除当前账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            manager_id: row.id,
            status: 0,
          };
          const { code } = await statusBusinessAdmin(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "删除成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    recoverAdmin(row) {
      let that = this;
      this.$confirm("是否恢复当前账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            manager_id: row.id,
            status: 1,
          };
          const { code } = await statusBusinessAdmin(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
    async getAllRoleList() {
      const { code, data } = await businessStatusRoleList();
      if (code == 200) {
        this.roleList = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.derive {
  margin-bottom: 10px;
}
</style>